import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import { useCallApi } from '@/@core/services/axiosCall';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useMetaStore = defineStore('meta', () => {
  const state = reactive({
    total: 0,
    metas: [],
    defaultGeolocation: [-3.285215,114.590771]
  });

  async function getManyMeta() {
    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: '/metas'
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    state.metas = result.metas;
    state.total = result.count;

    return result;
  }

  async function postMeta(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: '/metas',
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function putMeta(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: '/metas/' + data.id,
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function deleteMeta(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: '/metas/' + data.id
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }
  return {
    ...toRefs(state),
    getManyMeta,
    postMeta,
    deleteMeta,
    putMeta
  };
});
