



























































































































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LCircle } from "vue2-leaflet";

// @ts-ignore
import L, { Icon, latLng  } from 'leaflet';
import useModelValue from '@/@core/helpers/modelValue';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { useMetaStore } from '@/modules/meta/services/store';

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export default defineComponent({
    name: 'KMapInput',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        KDialog,
        LTooltip,
        LCircle
    },
    props: {
        tooltipText: {
            type: String,
            required: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { attrs, emit }) {
        const defaultGeolocation = useMetaStore().defaultGeolocation
        const modelValue = useModelValue({ attrs, emit })
        const showMap = ref(true)
        const mapAccuration = ref(50)
        const showLocationPermissionRequest = ref(false)
        const isTryGetPosition = ref(false)
        const mapCurrentPoint: Ref<any> = ref(null)
        const mapData = ref({
            zoom: 16.5,
            center: latLng(Number(modelValue.value.latitude ?? defaultGeolocation[0]), Number(modelValue.value.longitude ?? defaultGeolocation[1])) as unknown as any[],
            url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            options: {
                zoomSnap: 0.5
            },
        })
        
        const zoomUpdate = (zoom: number) => mapData.value.zoom = zoom
        const centerUpdate = (center: any) => mapData.value.center = center

        const handleMapClick = (event: any) => {
            if (props.readonly) return

            const { latlng: { lat, lng } } = event

            const geolocation = {
                latitude: lat,
                longitude: lng
            }

            addMark(geolocation)
        }

        const addMark = ({latitude, longitude}: any) => {
            modelValue.value = {
                latitude,
                longitude
            }
            mapData.value.center = [latitude, longitude]
        }

        const getCurrentPosition = async () => {
            let options = {
                enableHighAccuracy: true,
                timeout: 8000,
                maximumAge: 1
            }

            try {
                const { coords } = await new Promise((resolve, reject) => {
                    navigator.geolocation.watchPosition(resolve, reject, options);
                });

                return {
                    result: coords,
                    error: null
                }
            } catch (error) {
                return {
                    coords: null,
                    error
                }
            }
        }

        const locationPermissionRequest = async () => {
            const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'})
            const hasPermission = permissionStatus?.state == 'granted'

            if (!hasPermission) showLocationPermissionRequest.value = true
            else requestLocation(undefined)
        }

        const requestLocation = async (callback: any): Promise<any> => {
            showLocationPermissionRequest.value = false
            isTryGetPosition.value = true

            const {result, error}: any = await getCurrentPosition()

            if (error) {
                alert('Ada kesalahan, silahkan muat ulang halaman\n' + error.message)
                isTryGetPosition.value = false
                mapData.value.center = defaultGeolocation

                return
            }

            const { latitude, longitude, accuracy } = result
            const treshold = 100
            const isAccurate = accuracy && accuracy < treshold
            mapAccuration.value = accuracy

            mapCurrentPoint.value = [latitude, longitude]
            mapData.value.center = [latitude, longitude]

            isTryGetPosition.value = false
        }

        const saveCurrentPosition = () => {
            const [latitude, longitude] = mapCurrentPoint.value
            const geolocation = {
                latitude,
                longitude
            }
            
            if (!geolocation) return
            
            addMark(geolocation)
            mapCurrentPoint.value = null
            // mapAccuration.value = 0
        }

        watch(modelValue, (val) => {
            if (val) {
                setTimeout(() => {
                    const hasValue = val.latitude

                    if (!hasValue) return

                    mapData.value.center = latLng(Number(val.latitude ?? 0), Number(val.longitude ?? 0)) as unknown as any[]
                }, 200)
            }
        })

        return {
            mapData,
            showMap,
            modelValue,
            zoomUpdate,
            centerUpdate,
            addMark,
            handleMapClick,
            showLocationPermissionRequest,
            requestLocation,
            redIcon,
            mapCurrentPoint,
            locationPermissionRequest,
            isTryGetPosition,
            saveCurrentPosition,
            isMobile,
            mapAccuration
        }
    }
})
